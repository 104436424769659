<template>
  <div v-if="scriptLoaded" class="review-link-widget">
    <div class="row">
      <div class="col">
        <h3 class="h1 text-center text-white mb-4">Find Your Business</h3>
      </div>
    </div>
    <div class="row">
      <div :class="{ 'col-sm-8': step === 1, 'col-12': step > 1 }">
        <vue-google-autocomplete id="map"
                                 ref="address"
                                 types="establishment"
                                 classname="form-control"
                                 :class="{ 'has-error': errors.business }"
                                 :fields="placeFields"
                                 placeholder="Start Typing your business to generate your Google review link..."
                                 @placechanged="selected"
                                 @inputChange="resetErrors"
        ></vue-google-autocomplete>
        <span v-if="errors.business" class="error">{{ errors.business }}</span>
      </div>
      <div v-if="step === 1" class="col-sm-4 mb-3 mt-sm-0 mt-2 text-center">
        <b-spinner v-if="isBusy" small />
        <button v-else class="btn btn-success form-control" @click="createProspect">Generate My Link</button>
      </div>
    </div>
    <div v-if="step === 2" class="row mt-3">
        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-6 mt-2">
              <input v-model="form.first_name" type="text" class="form-control" :class="{ 'has-error': errors.first_name }" placeholder="Your First Name Here..." @keyup="resetErrors" />
              <span v-if="errors.first_name" class="error">{{ errors.first_name }}</span>
            </div>
            <div class="col-lg-6 mt-2">
              <input v-model="form.last_name" type="text" class="form-control" :class="{ 'has-error': errors.last_name }" placeholder="Your Last Name Here..." @keyup="resetErrors" />
              <span v-if="errors.last_name" class="error">{{ errors.last_name }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 mt-2">
              <input v-model="form.email" type="text" class="form-control" :class="{ 'has-error': errors.email }" placeholder="Your Email Address Here..." @keyup="resetErrors" />
              <span v-if="errors.email" class="error">{{ errors.email }}</span>
            </div>
            <div class="col-lg-6 mt-2">
              <input v-model="form.phone_number" v-mask="'(###) ###-####'" type="tel" class="form-control" :class="{ 'has-error': errors.phone_number }" placeholder="Your Phone Number Here..." @keyup="resetErrors" />
              <span v-if="errors.phone_number" class="error">{{ errors.phone_number }}</span>
            </div>
          </div>
        </div>
        <div class="col-lg-4 m-lg-auto text-center mt-4">
            <b-spinner v-if="isBusy" small />
            <button v-else class="form-control btn btn-success" @click="createLead">Get My Link</button>
        </div>
    </div>
    <div v-if="step === 3" class="row mt-3">
        <div class="col-md-6">
          <div class="link-display">
            <div class="mb-2 float-left">
              <div class="float-left"><img src="https://review-link.sfo3.cdn.digitaloceanspaces.com/Icon.png" style="max-height:40px" alt=""></div>
              <div class="intro-text float-left">
                <strong>Get more reviews</strong>
                <em>Direct customers to leave a review for this business</em>
              </div>
            </div>
            <div class="input-group border-0">
              <input ref="linkText" v-model="customLink" disabled type="text" class="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2">
              <div class="input-group-append">
                <button class="btn" @click="copyLink"><Feather class="align-middle" type="copy" /></button>
              </div>
            </div>
          </div>
          <a target="_blank" :href="`https://search.google.com/local/writereview?placeid=${placeResultData.place_id}`" class="mt-4 btn btn-warning d-block">View Business Review Link</a>
          <a target="_blank" :href="placeResultData.url" class="mt-4 btn btn-success d-block">View Business On Map</a>
        </div>
        <div class="col-md-6 text-center align-self-center">
          <iframe
              v-if="mapQuery"
              v-show="mapReady"
              class="map-frame"
              width="525"
              height="267"
              style="border:0"
              loading="lazy"
              allowfullscreen
              referrerpolicy="no-referrer-when-downgrade"
              :src="`https://www.google.com/maps/embed/v1/place?key=${googleApiKey}&q=${mapQuery}`"
              @load="mapReady = true"
          >
          </iframe>
          <b-spinner v-if="!mapReady" class="mt-sm-3" />
        </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { countryToAlpha2 } from "country-to-iso";
import Vue from "vue";
import axios from "axios";
export default {
  components:{
    VueGoogleAutocomplete
  },
  props:{
    // eslint-disable-next-line vue/prop-name-casing
    agency_id: {
      type:Number,
      default:0
    },
    // eslint-disable-next-line vue/prop-name-casing
    googleApiKey:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      step:1,
      street:'',
      place:null,
      addressData:null,
      reduced_address:null,
      placeResultData: {
        place_id:'',
        url:''
      },
      placeFields:['address_components', 'adr_address', 'alt_id', 'formatted_address', 'geometry', 'icon', 'id', 'name', 'photo', 'place_id', 'scope', 'type', 'url', 'vicinity', 'website', 'formatted_phone_number'],
      scriptLoaded:false,
      mapReady:false,
      isBusy:false,
      mapQuery:null,
      errors:{},
      unique_id:'',
      form:{
        first_name:'',
        last_name:'',
        email:'',
        phone_number:'',
        business_name:'',
        business_phone:'',
        business_website:'',
        address:'',
        city:'',
        state:'',
        zip:'',
        country:'',
        lat:'',
        lng:'',
        place_id:'',
        google_maps_url:'',
        agency_id:'',
        types:[]
      }
    }
  },
  metaInfo () {
    return {
      script: [{
        src: `https://maps.googleapis.com/maps/api/js?key=${this.googleApiKey}&libraries=places`,
        async: true,
        defer: true,
        callback: () => this.initMaps()
      }]
    }
  },
  computed: {
    addressString(){
      let address_string = '';
      if(this.placeResultData.hasOwnProperty('street_number') && this.placeResultData.hasOwnProperty('route')){
        address_string = this.placeResultData.street_number + ' ' + this.placeResultData.route;
        if(this.placeResultData.hasOwnProperty('subpremise')){
          address_string += ' '+this.placeResultData.subpremise;
        }
      }else{
        address_string = this.placeResultData.formatted_address.split(',')[0]
      }
      return address_string;
    },
    customLink(){
      return window.location.origin+'/r/'+this.unique_id;
    }
  },

  mounted() {
    this.form.agency_id = this.agency_id;
  },

  methods: {
    copyLink() {
      navigator.clipboard.writeText(this.customLink);
      Vue.$toast.open({
        message: 'Copied',
        type: 'success',
        duration: 5000,
      })
    },
    resetErrors(){
      this.errors = {};
    },
    createProspect(){
      this.isBusy = true;
      this.resetErrors();
      if(this.addressData === null){
        this.errors.business = "Please select your business before trying to generate the link";
      }
      if(this.addressData !== null && (!this.placeResultData.hasOwnProperty('place_id') || (this.placeResultData.hasOwnProperty('place_id') && this.placeResultData.place_id === ""))){
        this.errors.business = "Sorry, but we could not find a place ID identifier for your business";
      }
      if(Object.keys(this.errors).length){
        this.isBusy = false;
        return
      }
      this.reduced_address = this.placeResultData.address_components.reduce((seed, { long_name, types }) => {
        types.forEach(t => {
          seed[t] = long_name;
        });
        return seed;
      }, {});
      this.form.business_name = this.placeResultData.name;
      this.form.business_phone = this.placeResultData.formatted_phone_number;
      this.form.business_website = this.placeResultData.website;
      this.form.address = this.addressString;
      this.form.city = this.reduced_address.hasOwnProperty('neighborhood') ? this.reduced_address.neighborhood : this.addressData.locality;
      this.form.state = this.addressData.administrative_area_level_1;
      this.form.zip = this.addressData.postal_code;
      this.form.country = countryToAlpha2(this.addressData.country);
      this.form.lat = this.addressData.latitude;
      this.form.lng = this.addressData.longitude;
      this.form.place_id = this.placeResultData.place_id;
      this.form.google_maps_url = this.placeResultData.url;
      this.form.types = this.placeResultData.types;
      axios.post('/create-lead',this.form)
          .then((res) => {
            this.unique_id = res.data.data.unique_id;
            this.step++;
            this.isBusy = false;
          })
          .catch((error) => {
            this.isBusy = false;
            console.log(error)
          })
    },
    checkErrors(){
      if(this.form.first_name === ''){
        this.errors.first_name = "Please enter First Name";
      }
      if(this.form.last_name === ''){
        this.errors.last_name = "Please enter Last Name";
      }
      if(!/^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(this.form.email)){
        this.errors.email = "Please enter a valid email"
      }
      if(!(/^\(\d{3}\)\s\d{3}-\d{4}/.test(this.form.phone_number))){
        this.errors.phone_number = "Please enter a valid phone number"
      }
    },
    createLead(){
      this.isBusy = true;
      this.resetErrors();
      this.checkErrors();
      if(this.form.business_name === ''){
        // Somehow they are here and a prospect was not created
        this.createProspect();
      }
      if(Object.keys(this.errors).length){
        this.isBusy = false;
        return
      }
      axios.post('/update-lead',this.form)
          .then((res) => {
            this.step++;
            this.isBusy = false;
          })
          .catch((error) => {
            this.isBusy = false;
            console.log(error)
          })
    },
    initMaps(){
      this.scriptLoaded = true;
      // this.$refs.address.focus();
    },
    selected(addressData, placeResultData){
      this.addressData = addressData;
      this.placeResultData = placeResultData;
      this.mapQuery = encodeURIComponent(placeResultData.name+' '+placeResultData.formatted_address);
    },
    updateAddress(){
      if(this.placeResultData) {
        this.$refs.address.update(this.placeResultData.name + ' ' + this.placeResultData.formatted_address);
      }
    }
  },
}
</script>
<style lang="scss" scoped>
  .review-link-widget{
      font-size: 1rem;
      margin: 30px 0;
      padding: 20px;
      background: #0e8dc7;
      input, button, .btn{
        font-size: 1.25rem;
      }
      input.has-error{
        border: 1px solid red;
      }
      .link-display{
        padding: 10px;
        background: #eff3f7;
        border-radius: 10px;
        .intro-text{
          padding-left: 20px;
          strong{
            font-weight: bold;
            display: block;
            font-size: 16px;
            margin: 0;
          }
          em{
            margin: 0;
            font-size: 13px;
          }
        }
        .input-group-append button{
          background: #537bba;
          color: white;
        }
      }
  }
  @media screen and (max-width: 767px){
    iframe.map-frame{
      max-width: 100%;
      height: auto;
      margin-top: 25px;
    }
  }
</style>
<style lang="scss">
  .review-link-widget{
    .vue-tel-input{
      background: #fff;
      border-radius: 5px;
      border: 0;
      input{
        font-size: 1.25rem;
        border-radius: 0 5px 5px 0;
      }
    }
  }
</style>
